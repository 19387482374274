.banner {
 // background-color: aqua;
  z-index: 100;
  //position: relative;
  //height: 50vh;
  // @media (max-width: $layout-breakpoint-medium) {
  //   height: 78vh;
  //  }
  // @media (max-width: 600px) {
  //   height: 70vh;
  //  }

  .banner-row {
    //overflow: hidden;

    //display: flex;
    //align-items: center;
    // @media (max-width: $layout-breakpoint-medium) {
    //   &:first-child {
    //     margin-top: 0px;
    //   }
    // }

    &.center {
      justify-content: center;
      text-align: center;

      .row-letter {
        left: 0;
      }
    }

    &.right {
      justify-content: flex-end;
    }

    .row-title,
    .row-letter {
      font-size: 18rem;
      font-weight: 400;
      position: relative;
      letter-spacing: -0.3rem;
      white-space: nowrap;
      display: inline;
      margin-left: 20px;
      //padding-bottom: 30px;

      @media (max-width: $layout-breakpoint-medium) {
        font-size: 13rem;
      }
    }

    .row-col {
      width: 70%;

      // display: flex;
      // align-content: left;
      // justify-content: center;
      // @media (max-width: $layout-breakpoint-medium) {
      //   width: 90%;
      // }
      // @media (max-width: 1240px) {
      //   &:last-child {
      //     width: 90%;
      //   }
      // }

      // @media (max-width: $layout-breakpoint-xsmall) {
      //   width: 100%;
      //   justify-content: flex-start;
      // }

      // .row-message {
      //   font-weight: 500;
      //   font-size: 1.8rem;
      //   line-height: 2.6rem;
      //   width: 320px;
      // }
      :last-child {
        letter-spacing: -0.5rem;
        margin-top: 30px;
        //margin-bottom: 30px;
        font-size: 8rem;
        display: inline-block;
        white-space: normal;

        @media (max-width: $layout-breakpoint-medium) {
          font-size: 6rem;
        }
      }
    }

    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 160px;

      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }

      @media (max-width: 1240px) {
        display: none;
      }

      span {
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;

        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }
}

.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  //height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 800px;
    display: flex;
    //border-bottom: 1px solid;
  }

  &.final {
    display: block;
    //top: -160px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;

    // border-bottom: 1px solid red;
    //margin-bottom:50px;
    // @media (max-width: 1350px) {
    //   margin-top: -150px;
    // }

    // @media (max-width: 990px) {
    //   margin-top: -100px;
    // }

    // @media (max-width: 690px) {
    //   margin-top: 0px;
    // }

    // @media (max-width: 420px) {
    //   margin-top: 80px;
    // }

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.title-text {
  margin-left: 8%;
  font-size: 12rem;
  font-weight: 600;
  //background-color: gray;
  min-width: 80vw;
  margin-top:10vh;
  margin-bottom:15vh;
  white-space: normal !important;
  word-break: break-word !important;
  @media (max-width: $layout-breakpoint-medium) {
    font-size: 9rem;
    // white-space: normal!important;
    // word-break: break-word!important;
  }

  @media (max-width: 600px) {
    // margin-top: -30px;
    font-size: 6.7rem;
    // width:90vw;
    // white-space: normal!important;
    // word-break: break-word!important;
    //overflow-wrap: normal;
    //width: 20%;
  }
}