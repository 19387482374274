@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

html {
  font-size: 62.5%;
  line-height: 1.25;
  body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

p,
span {
  font-size: 1.4rem;
}

a {
  font-weight: 600;
}
