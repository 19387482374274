.about-section {
  //margin-top: 8vh;
  //padding-bottom: 20vh;
    text-align: center;
    //background-color: coral;
  //   @media (max-width: 1200px) {
  //     margin-top: -180px;
  //     // background-color:aqua;
  //   }
  // @media (max-width: 800px) {
  //   margin-top: -350px;
  //   // background-color:aqua;
  // }
  // @media (max-width: 550px) {
  //   margin-top: -500px;
  //   // background-color:#626262;
  // }
  p {
    //width:100vw;
    font-size: 35px;
    line-height: 1.55;
    opacity: 0;
    padding: 0 15vw;
    text-wrap:balance;
    
    @media (max-width: $layout-breakpoint-medium) {
      padding: 0 5vw;
      font-size: 30px;
    }
    @media (max-width: 600px) {
      font-size: 24px;
      padding: 0 5vw;
      margin-top:5vh;
    }

    >div {
      opacity: 0;
      transform: translateY(0px);
    }

    &.is-reveal {
      opacity: 1;
    }
  }
}

.section-header {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Syncopate', sans-serif;
  color: #626262;
}

.background-div {
  //position: absolute;
  // top: -40vh;
  // left: -20vw;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/images/gradient-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100; /* Place the photo div below the text div */
  padding-bottom: 15vh;

  
}